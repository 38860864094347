import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
//import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Projects",
  posts = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1629904853716-f0bc54eea481?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
      category: "Website",
      date: "February 2021",
      title: "IdealDevOps",
      description:
        "Having a website in these times is  a necessity. It’s the way people know you globally and we make sure your presentation is perfect. 80% of today’s consumers think a website makes your business more credible than companies who only have social media profiles.",
      url: "/#",
      featured: true
    },
    getPlaceholderPost(),
    getPlaceholderPost2(),
    getPlaceholderPost3(),
    getPlaceholderPost4(),
    getPlaceholderPost5(),
    getPlaceholderPost6(),
    getPlaceholderPost7(),
    getPlaceholderPost8(),

    
  ]
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a" href={post.url} target="_blank" rel="noreferrer">
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = () => ({
  imageSrc:
    "https://turk-dental.com/wp-content/uploads/2021/05/turk-dental-11.jpg",
  category: "Website",
  date: "March 2021",
  title: "TURK DENTAL FIER",
  description:
    "A website made for one of the best Dentistry",
  url: "https://turk-dental.com"
});
const getPlaceholderPost2 = () => ({
  imageSrc:
    "https://albes.al/wp-content/uploads/2021/07/Albes122-min.jpg",
  category: "Website",
  date: "July 2021",
  title: "ALBES",
  description:
    "A website made according the instructions of client.",
  url: "https://albes.al"
});
const getPlaceholderPost3 = () => ({
  imageSrc:
    "https://egnatia-restaurant.al/wp-content/uploads/2021/09/Egnatia-gallery.jpg",
  category: "Website",
  date: "August 2021",
  title: "EGNATIA",
  description:
    "A restaurant needs a website for itself.",
  url: "https://egnatia-restaurant.al"
});
const getPlaceholderPost4 = () => ({
  imageSrc:
    "https://images.unsplash.com/photo-1566060475410-1159300f046f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
  category: "Website",
  date: "January 2022",
  title: "KAD TECH",
  description:
    "The best place for security solutions.",
  url: "https://kad-tech.al/"
});
const getPlaceholderPost5 = () => ({
  imageSrc:
    "https://numa.qrcode.al/assets/numa.jpg",
  category: "QrCode Menu",
  date: "December 2021",
  title: "NUMA",
  description:
    "A website made according the instructions of client.",
  url: "https://numa.qrcode.al/"
});
const getPlaceholderPost6 = () => ({
  imageSrc:
    "https://oopslounge.com/themes/oops/gallery/9.jpg",
  category: "QrCode Menu",
  date: "March 2021",
  title: "OOPS LOUNGE",
  description:
    "A website made according the instructions of client.",
  url: "https://oopslounge.com/store/oopslounge/"
});
const getPlaceholderPost7 = () => ({
  imageSrc:
    "https://cdn.pixabay.com/photo/2013/07/13/10/11/qr-code-156717_960_720.png",
  category: "QrCode",
  date: "March 2021",
  title: "QRCODE",
  description:
    "A website made according the instructions of client.",
  url: "https://www.qrcode.al/"
});
const getPlaceholderPost8 = () => ({
  imageSrc:
    "https://egnatia-restaurant.al/wp-content/uploads/2021/09/Egnatia-gallery-66.jpg",
  category: "QrCode",
  date: "September 2021",
  title: "EGNATIA MENU",
  description:
    "A website made according the instructions of client.",
  url: "https://egnatia.qrcode.al/"
});